<template>
	<div>
		<Header ref="Header"/>
		<div class="space-container">
			<div class="space-filter-box">
				<div class="space-filter-list">
					<div class="space-filter-item row">
						<div class="space-filter-title">用途：</div>
						<div class="space-filter-namebox flex">
							<div v-for="(item,index) in activity" :key="index">
								<div @click="chooseActivity(index,item.dictLabel)"
									:class="activityIndex==index?'space-filter-nameselected':'space-filter-name'"
									v-html="item.dictLabel"></div>
							</div>
						</div>
					</div>
					<div class="space-filter-item row">
						<div class="space-filter-title">类型：</div>
						<div class="space-filter-namebox flex">
							<div v-for="(item,index) in type" :key="index">
								<div @click="chooseType(index,item.dictValue)"
									:class="typeIndex==index?'space-filter-nameselected':'space-filter-name'"
									v-html="item.dictLabel"></div>
							</div>
						</div>
					</div>
					<div class="space-filter-item row">
						<div class="space-filter-title">面积：</div>
						<div class="space-filter-namebox flex">
							<div v-for="(item,index) in size" :key="index">
								<div @click="chooseSize(index)"
									:class="sizeIndex==index?'space-filter-nameselected':'space-filter-name'"
									v-html="item.name"></div>
							</div>
						</div>
					</div>
					<div class="space-filter-item row">
						<div class="space-filter-title">人数：</div>
						<div class="space-filter-namebox flex">
							<div v-for="(item,index) in capacity" :key="index">
								<div @click="chooseCapacity(index)"
									:class="capacityIndex==index?'space-filter-nameselected':'space-filter-name'"
									v-html="item.name"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="space-tips-box flex">
				<div class="space-tips-left">为您找到{{Total}}个场地</div>
				<div class="space-tips-right flex">
					<div v-for="(item,index) in tips" :key="index">
						<div class="space-tips-item flex">
							<div class="space-tips-pic">
								<img :class="arrow[index].up?'space-tips-upred':'space-tips-up'"
									class="jump" src="../../public/images/arrow_06.png" />
								<img :class="arrow[index].down?'space-tips-upred':'space-tips-up'"
									class="jump" src="../../public/images/arrow_07.png" />
							</div>
							<div class="sapce-tips-title jump" @click="chooseColumn(index)">{{item}}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="space-list-box">
				<div v-for="(item,index) in space" :key="index">
					<div class="space-list-item row">
						<img @click="pageTo(item.id)" class="space-list-pic jump" :src="item.picture"/>
						<div class="row space-list-between">
							<div class="space-list-midle">
								<div @click="pageTo(item.id)" class="space-list-title jump">【{{item.city}}】{{item.name}}</div>
								<div class="space-list-palace flex">
									<img class="space-list-loc" src="../../public/images/place_01.png"/>
									<div class="space-list-address">{{item.address}}</div>
								</div>
								<!-- <div @click="pageTo(item.id)" v-html="item.description" class="space-list-description"></div> -->
								<div class="space-list-lable flex">
									<div v-for="(item,index) in item.mark" :key="index">
										<div class="space-list-btn">{{item}}</div>
									</div>
								</div>
							</div>
							<div class="space-list-right">
								<img src="../../public/images/collect_01.png" v-if="item.favorites==0" 
								class="space-list-collect jump" @click="collect(index,item.id)">
								<img src="../../public/images/collect_02.png" v-if="item.favorites==1"
								class="space-list-collect jump" @click="cancel(index,item.id)">
								<!-- <div class="space-list-price row" v-if="userInfo.id">
									<div class="space-list-num">￥{{item.price}}</div>
									<div class="space-list-qi">{{item.priceUnit}}</div>
								</div> -->
								<div @click="pageTo(item.id)" class="space-list-bigbtn jump">查看详情</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="bar-box" v-if="paginationShow">
				<el-pagination 
					class="bar-switch"
					background
					layout="prev, pager, next, jumper, total"
					:page-size="searchDate.pageSize"
					:total="Total"
					@current-change="choosePage">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import  Header from '@/components/header.vue';
	import {
		mapState
	} from 'vuex';
	export default {
		components:{Header},
		data() {
			return {
				paginationShow:true,
				showPrice:false,
				searchDate:{
					pageNum:1,
					pageSize:10,
					orderByColumn:'createTime',
					isAsc:'desc',
				},
				Total:0,
				pageTotal:0,
				activityIndex:0,
				typeIndex:0,
				sizeIndex:0,
				capacityIndex:0,
				activity: [],
				type: [],
				size: [
					{name:'不限',sizeMin:0,sizeMax:0},
					{name:'100㎡以下',sizeMin:0,sizeMax:100},
					{name:'100㎡—300㎡',sizeMin:100,sizeMax:300},
					{name:'300㎡—500㎡',sizeMin:300,sizeMax:500},
					{name:'500㎡—1000㎡',sizeMin:500,sizeMax:1000},
					{name:'1000㎡—1500㎡',sizeMin:1000,sizeMax:1500},
					{name:'1500㎡',sizeMin:1500,sizeMax:0},
				],
				capacity: [
					{name:'不限',capacityMin:0,capacityMax:0,},
					{name:'20人以下',capacityMin:0,capacityMax:20,},
					{name:'20-50人',capacityMin:20,capacityMax:50,},
					{name:'50-100人',capacityMin:50,capacityMax:100,},
					{name:'100-300人',capacityMin:100,capacityMax:300,},
					{name:'300-500人',capacityMin:300,capacityMax:500,},
					{name:'500人以上',capacityMin:500,capacityMax:0,}
				],
				tips: ['按时间排序', '按价格排序', '按面积排序'],
				arrow: [
					{up: false,down: false,orderBy:'createTime'},
					{up: false,down: false,orderBy:'price'},
					{up: false,down: false,orderBy:'size'},
				],
				space:[],
				orderClick:0,
			}
		},
		computed:{
			...mapState(['userInfo']),
		},
		mounted() {
			// 带有关键词
			if(this.$route.query.searchValue){
				this.searchDate.searchValue=this.$route.query.searchValue
			}
			//设置头部
			this.$refs.Header.setIndex(1,this.$route.query.searchValue);
			//获取用途列表
			this.$api.getDictType('app_activities_type').then(res=>{
				console.log(res,'用途列表');
				this.activity=res.rows;
				// 带有活动类型
				if(this.$route.query.activities){
					for(let i=0;i<this.activity.length;i++){
						if(this.activity[i].dictLabel==this.$route.query.activities){
							this.activityIndex=i;
						}
					}
				}
			})
			// 获取类型列表
			this.$api.getDictType('app_place_type').then(res=>{
				console.log(res,'类型列表');
				this.type.push({dictLabel:'不限',dictValue:-1});
				this.type.push(...res.rows)
				// 带有场地类型
				if(this.$route.query.type){
					for(let i=0;i<this.type.length;i++){
						if(this.type[i].dictValue===this.$route.query.type){
							this.typeIndex=i;
						}
					}
				}
			})
			// 带有活动类型
			if(this.$route.query.activities){
				this.searchDate.activities=this.$route.query.activities;
			}
			// 带有场地类型
			if(this.$route.query.type){
				this.searchDate.type=this.$route.query.type;
			}
			//带有面积
			if(this.$route.query.sizeIndex){
				this.sizeIndex=this.$route.query.sizeIndex;
				var num = this.size[this.sizeIndex].sizeMax-this.size[this.sizeIndex].sizeMin;
				if(num==0){
					delete this.searchDate.sizeMax;
					delete this.searchDate.sizeMin;
				}else if(num<0){
					delete this.searchDate.sizeMax;
					this.searchDate.sizeMin=this.size[this.sizeIndex].sizeMin;
				}else{
					this.searchDate.sizeMin=this.size[this.sizeIndex].sizeMin;
					this.searchDate.sizeMax=this.size[this.sizeIndex].sizeMax;
				}
			}
			//带有容量
			if(this.$route.query.capacityIndex){
				this.capacityIndex=this.$route.query.capacityIndex;
				var num = this.capacity[this.capacityIndex].capacityMax-this.capacity[this.capacityIndex].capacityMin;
				if(num==0){
					delete this.searchDate.capacityMax;
					delete this.searchDate.capacityMin;
				}else if(num<0){
					delete this.searchDate.capacityMax;
					this.searchDate.capacityMin=this.capacity[this.capacityIndex].capacityMin;
				}else{
					this.searchDate.capacityMax=this.capacity[this.capacityIndex].capacityMax;
					this.searchDate.capacityMin=this.capacity[this.capacityIndex].capacityMin;
				}
			}
			this.placeSearch();
		},
		methods: {
			// 选择用途
			chooseActivity(index,dictLabel){
				this.activityIndex=index;
				if(dictLabel=='其他'){
					delete this.searchDate.activities;
				}else{
					this.searchDate.activities=dictLabel;
				}
				this.searchDate.pageNum=1;
				this.paginationShow=false;
				// this.paginationShow=true;
				this.placeSearch();
			},
			// 选择类型
			chooseType(index,dictValue){
				this.typeIndex=index;
				if(dictValue<0){
					delete this.searchDate.type;
				}else{
					this.searchDate.type=dictValue;
				}
				this.searchDate.pageNum=1;
				this.paginationShow=false;
				this.placeSearch();
			},
			// 选择面积
			chooseSize(index){
				this.sizeIndex=index;
				var num = this.size[index].sizeMax-this.size[index].sizeMin;
				if(num==0){
					delete this.searchDate.sizeMax;
					delete this.searchDate.sizeMin;
				}else if(num<0){
					delete this.searchDate.sizeMax;
					this.searchDate.sizeMin=this.size[index].sizeMin;
				}else{
					this.searchDate.sizeMin=this.size[index].sizeMin;
					this.searchDate.sizeMax=this.size[index].sizeMax;
				}
				this.searchDate.pageNum=1;
				this.paginationShow=false;
				this.placeSearch();
			},
			chooseCapacity(index){
				this.capacityIndex=index;
				var num = this.capacity[index].capacityMax-this.capacity[index].capacityMin;
				if(num==0){
					delete this.searchDate.capacityMax;
					delete this.searchDate.capacityMin;
				}else if(num<0){
					delete this.searchDate.capacityMax;
					this.searchDate.capacityMin=this.capacity[index].capacityMin;
				}else{
					this.searchDate.capacityMax=this.capacity[index].capacityMax;
					this.searchDate.capacityMin=this.capacity[index].capacityMin;
				}
				this.searchDate.pageNum=1;
				this.placeSearch();
			},
			//选择排序字段
			chooseColumn(index){
				//初始化
				this.arrow= [
					{up: false,down: false,orderBy:'createTime'},
					{up: false,down: false,orderBy:'price'},
					{up: false,down: false,orderBy:'size'},
				],
				this.searchDate.orderByColumn=this.arrow[index].orderBy;
				if(this.orderClick>1){
					this.orderClick=0;
				}
				if(this.orderClick===0){
					this.searchDate.isAsc='desc';
					this.arrow[index].down=true;
				}else{
					this.searchDate.isAsc='asc';
					this.arrow[index].up=true;
				}
				this.orderClick++;
				this.placeSearch();
			},
			// 跳转详情
			pageTo(id){
				this.$router.push({
					path: 'introduction',
					query: {
						id:id,
					}
				});
			},
			// 选择分页
			choosePage(num){
				this.searchDate.pageNum=num;
				this.placeSearch();
			},
			// 场地搜索
			placeSearch(){
				this.searchDate.city=localStorage.getItem('city') || '北京';
				console.log(this.searchDate,'当前传参');
				this.$api.getPlaceList(this.searchDate).then(res=>{
					// console.log(this.searchDate,'当前传参');
					console.log(res,'场地列表');
					for(let i=0;i<res.rows.length;i++){
						res.rows[i].mark=res.rows[i].mark.split(',');
					}
					this.space=res.rows;
					this.Total=parseInt(res.total);
					this.paginationShow=true;
					// this.pageTotal=Math.ceil(res.total / this.searchDate.pageSize);
					// console.log(this.pageTotal,'总页数')
				});
			},
			// 收藏
			collect(index,placeId){
				this.$api.addFavorites(placeId).then(res=>{
					console.log(res,'收藏返回');
					if(res){
						this.space[index].favorites=1;
					}
				})
			},
			// 取消收藏
			cancel(index,placeId){
				this.$api.removeFavorites(placeId).then(res=>{
					console.log(res,'取消收藏返回');
					if(res){
						this.space[index].favorites=0;
					}
				})
			}
		}
	}
</script>

<style scoped>

</style>
